body {
  margin: 0;
  padding: 0;
  font-family: "Mulish", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Mulish", sans-serif;
}

/* a:link {
    color:#542a56;
    border: none;
}

a:hover {
    color:black;
}
  
a:visited {
    color: #876a89;
} */

.pfpurpleText {
  color: #542a56;
}

.grayText {
  color: #808080;
}

.App {
  text-align: center;
  background-color: #542b57;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

/*...MOBILE FORMATTING.......must be last........................................*/

@media (max-width: 800px) {
}
